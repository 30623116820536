import { initializeApp } from 'firebase/app';
import { getFirestore,getDoc,getDocs,collection,doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions,httpsCallable,connectFunctionsEmulator } from 'firebase/functions';
import { getHostName } from './functions';
// Initialize Firebase
const config = {
  apiKey: "AIzaSyA0woka73VZOVhkejcpyZuTZ16hgLuoAUA",
  authDomain: "menu-online-d025c.firebaseapp.com",
  projectId: "menu-online-d025c",
  storageBucket: "menu-online-d025c.appspot.com",
  messagingSenderId: "241015439518",
  appId: "1:241015439518:web:469cd97556666ff9d7bb8f",
  measurementId: "G-Q7VXH6NJC6"
};
//firebase.firestore().enablePersistence()
const app = initializeApp(config);

const functions = getFunctions(app,'europe-west3')
if (getHostName() === "localhost" || getHostName().startsWith("192.168")) {
  console.log("connectFunctionsEmulator")
  connectFunctionsEmulator(functions,"localhost",5002)
}
const auth = getAuth(app);
auth.useDeviceLanguage();
const firestore = getFirestore(app)
function callFunction (functionname){
  return httpsCallable(functions,functionname)
}

export {
  auth,
  firestore,
  functions,
  callFunction,
  app,
  getDoc,getDocs,collection,doc
}