import { withRouter,useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Swal from "sweetalert2";
import {  callFunction  } from "../provider/firebase";
import { getHostName, somethingWentWrong, successMessage } from "../provider/functions";
import SplashScreen from "./SplashScreen";
export default withRouter(GarsonCagir);
function GarsonCagir({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const language = useSelector((state) => state.language.data);
  const serverReducer = useSelector((state) => state.server.data);
   const [loading,setloading] = useState(false);
   function garsonCagirSend(value) {
    setloading(true);
    window.grecaptcha.enterprise.ready(()=> window.grecaptcha.enterprise.execute('6Lc7XZMqAAAAAOxV4_eE7mic2A4fDg_BxowGYlzR', {action: 'garsoncagir'}).then(token=>
    callFunction("garsonCagir")({
      token,
      server: match.params.server ? match.params.server : getHostName(),
      table: match.params.table,
      value
    })
      .then((res) => {
        if (res.data.error) {
         
        }
        if (res.data == true) {
          history.goBack();
         
          return successMessage(
            language.basarili,
            "",
            "success"
          );
        }
        return somethingWentWrong(
          language.hata,
           language.birseyler_ters_gidiyor,
           "error"
         );
      })
      .catch(() => {
        return somethingWentWrong(
          language.hata,
           language.birseyler_ters_gidiyor,
           "error"
         );
      })
      .finally(() => setloading(false))
    ))
 
  }
  if(loading){
    return <SplashScreen/>
  }
  return (
    <div>
     
      <div className="rp-app rp-search">
        <div className="container-fluid">
          <div className="rp-page-body " style={{ marginBottom: 100 }}>
            <div className="rp-page-header" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
              <div className="rp-hh-content flex-between">
                <div className="area">
                  <a onClick={history.goBack} className="link">
                    <i className="fa fa-arrow-left" />
                  </a>
                </div>
                <div className="area">
                  <div className="logo">
                    <img
                      src={
                        serverReducer.image
                          ? serverReducer.image
                          : "../../logo.png"
                      }
                      alt="Logo"
                    />
                  </div>
                </div>
                <div className="area" style={{minWidth:40}}>
                  {/* <a className="link">
                    <i className="fa fa-bell" />
                  </a> */}
                </div>
              </div>
            </div>
            {serverReducer.garsonCagir.map((v,k)=><button key={k} className="btn btn-block btn-dark mt-3" disabled={loading} onClick={()=>garsonCagirSend(v)}
            >{v}</button>)}   
          </div>
        </div>
      </div>
      
    </div>
  );
}
