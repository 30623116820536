import {motion,AnimatePresence} from 'framer-motion'
export function ScroolMotionDiv({children,...props}){
   return <motion.div initial={{ opacity: 0 }}
   whileInView={{ opacity: 1 }} {...props}>{children}</motion.div>
}
export function VariantContainerDiv({children,...props}){
   return <AnimatePresence ><motion.div variants={{hidden: { opacity: 1, scale: 0 },visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }}}
    initial="hidden"
    animate="visible" {...props}>{children}</motion.div></AnimatePresence>
}
export function VariantChildDiv({children,...props}){
   return <motion.div variants={{
      hidden: { y: 50, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1
      }
    }} {...props}>{children}</motion.div>
}
export function ScaleHoverButton({children,scale,...props}){
   return <motion.div 
   whileHover={{ scale: scale ?? 1.1 }}
   transition={{ type: "spring", stiffness: 400, damping: 10 }}{...props}>{children}</motion.div>
}
export function MotionInput({scale,...props}){
   return <motion.input whileFocus={{ scale: scale ?? 1.05 }} {...props}/>
}
export function MotionImage({...props}){
   return <motion.img
   initial={{ y: -10,scale:1.1 }}
   animate={{ y: 0,scale:1 }}
   transition={{
     type: "smooth",
     repeatType: "mirror",
     duration: 2,
     repeat: 2,
   }}
   {...props}
/>
}
export function MotionLeftToRight({children,...props}){
   return <motion.div initial={{ opacity: 0,x:-50 }} transition={{
      type: "smooth",duration:0.5}}
   whileInView={{ opacity: 1,x:0 }} {...props}>{children}</motion.div>
}